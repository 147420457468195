import React from 'react'
import tw, { css } from 'twin.macro'

const Rectangle = ({ color, width, height, position }) => (
    <div css={[color, width, height, position]} />
)

const RectangleQuote = () => {
    return (
        <div  css={tw`absolute top-0 right-0`}>
            <Rectangle 
                color={tw`bg-secondary-500`} 
                width={tw`w-8`} 
                height={tw`h-8`} 
                position={tw`relative right-14`}
            />
            <Rectangle 
                color={tw`bg-primary-500`} 
                width={tw`w-14`} 
                height={tw`h-14`} 
                position={tw`absolute right-0`}
            />
        </div>
    )
}
export default RectangleQuote;
