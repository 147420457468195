import PropTypes from 'prop-types'
import React from 'react'
import tw, { css, theme } from 'twin.macro'
import Heading from './heading'
import Text from './text'
import Button from './button'
import quote from '../images/icon-quote.svg'
import { global } from '../styles/global'
import { min } from '../utils/breakpoints'
import { HTMLType, LinkType, StyleType } from '../utils/prop-types'


const SimpleBlock = ({ title, description, callToAction, buttonTheme, style, ...props }) => {
  return (
    <div css={[tw`w-full`, style]}>
        <Heading
          headingType="h4"
          content={title}
          style={[ tw`font-normal`]}
          />  
        {
          description && 
          <Text
            content={description}
            style={[global`typography.body-lg`, tw`mt-6 text-primary-500 opacity-70 font-normal leading-vertical`]}
          />
        }

        {callToAction && (
            <Button
              type="primary"
              size="lg"
              theme={buttonTheme}
              label={callToAction.label}
              link={callToAction.link}
              style={tw`mt-6 w-fit-content lg:mt-24`}
              hideCaret={false}
            />
          )}
    </div>
  )
  
}

SimpleBlock.propTypes = {
  title: HTMLType,
  description: HTMLType,
  style: StyleType,
  callToAction: LinkType,
  props: PropTypes.object,
}

export default SimpleBlock
